import * as React from "react";
import NavBar from "../components/navbar/index.js";
import Logo from "../images/logo.png";
import { Link } from "gatsby";

export default function page() {
  return (
    <>
      <div className="min-h-[100vh] pt-16 pb-12 flex flex-col bg-white">
        <main className="flex-grow flex flex-col justify-center max-w-7xl w-full mx-auto px-4 sm:px-6 lg:px-8">
          <div className="flex-shrink-0 flex justify-center">
            <a href="/" className="inline-flex">
              <img className="h-12 w-auto" src={Logo} alt="comm-stem-logo" />
            </a>
          </div>
          <div className="py-16">
            <div className="text-center">
              <p className="text-sm font-semibold text-blue uppercase tracking-wide font-lexend-deca">
                404 error
              </p>
              <h1 className="pt-10 text-4xl font-extrabold text-gray-900 tracking-tight sm:text-5xl font-body">
                Page not found.
              </h1>
              <p className="pt-5 text-base text-gray-500 font-lexend-deca">
                Sorry, we couldn’t find the page you’re looking for.
              </p>
              <div className="pt-10">
                <Link
                  to={"/"}
                  className="text-base font-medium text-creamyBlue hover:text-skyBlue font-lexend-deca"
                >
                  Go back home<span aria-hidden="true"> &rarr;</span>
                </Link>
              </div>
            </div>
          </div>
        </main>
      </div>
    </>
  );
}
